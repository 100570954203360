
<template>
    <div class="managementStandardOfFranchisee">
        <!-- managementStandardOfFranchisee AGT004 "附件二-{{companyName}}村口壹站加盟商管理规范 -->
        <div class="content">
            <div class="title center bold">{{companyName}}</div>
            <div class="title center bold">村口壹站加盟商管理规范</div>
            <div class="indent5 mt3">本《村口壹站加盟商管理规范》为双方签署的《村口壹站加盟合作协议》、《农村普惠金融服务合作协议》（以下简称“主协议”）的附件，与主协议具备同等法律效力。</div>
            <div class="indent5 ">为规范服务站及加盟商管理，统一标准、优化操作，推动服务站及服务站各项业务持续、稳定、健康发展，制订本管理规范，作为加盟商管理制度及考核规则。</div>
            <div class=" mt3 bold title">第一章 加盟商管理</div>
            <div class=" mt3 bold">一、加盟商的定义</div>
            <div class="indent5 ">加盟商是指与{{companyName}}（以下简称“村口科技”）签署《村口壹站加盟合作协议》的加盟服务站的经营者，日常经营中称呼加盟商为“站长”。</div>
            <div class=" mt3 bold">二、加盟商的职责</div>
            <div class="indent5 ">提供独立场所并简单装修，提供办公桌椅。</div>
            <div class="indent5 ">自备业务周转金，缴纳设备押金，并负责服务站运营过程中的交通、水、电、网络、电话等费用和办公耗材。</div>
            <div class="indent5 ">负责服务站日常经营，服从村口科技及村口科技合作方管理，按照规定的产品、时间、场地和标准开展业务和服务，保证设备、服务站安全。</div>
            <div class=" mt3 bold">三、日常行为管理</div>
            <div class=" bold mt3">1.服务站管理</div>
            <div class=" ">1.1设备管理</div>
            <div class=" ">（1）摆放整齐，定期打扫，及时清理。</div>
            <div class=" ">（2）保障设备运行正常，遇故障及时报修，不得擅自拆解设备。</div>
            <div class=" ">（3）监督设备使用，避免人为损坏。</div>
            <div class=" ">1.2卫生管理</div>
            <div class=" ">（1）室内整洁，每日打扫。</div>
            <div class=" ">（2）宣传单、业务用品、办公用品整齐摆放，使用后及时归位。</div>
            <div class=" ">（3）服务站内不摆放非业务相关杂物。</div>
            <div class=" ">1.3标识管理</div>
            <div class=" ">（1）门头、标识牌、制度牌等广告标识及“合规经营承诺书”、“十严禁”(下附)等物料保持齐全、悬挂规范、整洁，对污、损、丢失情况及时修补或反馈。</div>
            <div class=" ">（2）不私自印制、张贴宣传品或违规使用村口科技合作方、村口科技标识。</div>
            <div class=" ">（3）不张贴、使用其他宣传资料。</div>
            <div class=" ">1.4安防管理</div>
            <div class=" ">（1）每日检查监控设备工作情况，保证正常供电、保持联网在线，不随意调整安装位置及角度。如遇角度调整、离线报警等问题及时处理。</div>
            <div class=" ">（2）办公区门随用随锁，禁止除加盟商、村口科技员工、村口科技合作方员工以外人员进入。</div>
            <div class=" ">（3）保险柜钥匙用完拔出，密码与钥匙妥善保管，密码盘用完随手打乱。</div>
            <div class=" ">（4）每日检查门窗坚固、开关情况，发现问题及时整改。</div>
            <div class=" ">（5）定期检查服务站线路，禁止在服务站使用大功率电器。</div>
            <div class=" ">（6）每周检查灭火器等消防器材是否有效、完好状况，发现问题及时更换。</div>
            <div class=" bold mt3">2.宣传营销</div>
            <div class=" ">2.1村民服务群</div>
            <div class=" ">（1）每名加盟商需组建至少一个村民（客户）服务群，由加盟商或其家属担任群主及管理员。</div>
            <div class=" ">（2）邀请服务站管理责任人及运营负责人入群，协助宣传管理。</div>
            <div class=" ">（3）严格根据相关法律法规，管理所建服务群，发现问题及时制止或清退。</div>
            <div class=" ">2.2.营销活动</div>
            <div class=" ">（1）定期举办营销活动，每月制订营销计划，保持服务站活性、扩大宣传。</div>
            <div class=" ">（2）对于村口科技、银行组织的营销活动须积极配合，知晓意义，提前做好活动准备。</div>
            <div class=" ">（3）营销活动应明确时间、目标、资源，提前准备预热，重点人群通过线上、线下结合的形式保证到场率。</div>
            <div class=" bold mt3">3．业务管理</div>
            <div class=" ">3.1农村普惠金融业务管理</div>
            <div class=" ">3.1.1业务规范</div>
            <div class="indent5 ">经村口科技合作银行向人民银行报备，以“村口壹站”为平台，提供小额取款、现金汇款、转账汇款、代理缴费、查询等基础支付服务。</div>
            <div class=" ">3.1.2业务办理</div>
            <div class=" ">（1）熟练掌握设备操作和业务办理流程，熟悉银行产品和规则。</div>
            <div class=" ">（2）严格按照村口科技和银行规定办理业务，按规定进行交易登记。</div>
            <div class=" ">（3）业务涉及现金必须使用点钞机当面点验，确认数额、真伪，双方无异议后再签字确认。</div>
            <div class=" ">（4）加盟商对业务及现金库存必须每日核对点验，保证账实相符。</div>
            <div class=" ">（5）掌握假币识别方法，并在服务站显著位置明示假币识别、举报投诉电话等注意事项，传授识别假币的基本方法，提高村民识别能力。</div>
            <div class=" ">（6）严格按照要求在服务站内办理业务，保证整个流程处在监控范围内。</div>
            <div class=" ">（7）办理现金业务要及时放入保险柜，大额现金（超过5万）不过夜，保证资金安全。</div>
            <div class=" ">（8）因事外出时张贴联系方式。</div>
            <div class=" ">（9）规范宣传，严格按照人民银行、合作银行以及村口科技要求的宣传口径进行准确宣传，不误导客户，不夸大宣传。</div>
            <div class=" ">3.1.3登记凭证</div>
            <div class=" ">（1）客户办理业务后，必须要求客户在交易凭条和登记簿上签字或按指纹确认。</div>
            <div class=" ">（2）妥善保管交易凭条和登记薄，按村口科技合作银行要求定期收归或销毁。</div>
            <div class=" ">3.1.4.日常管理</div>
            <div class=" ">（1）每日按时、准确通过加盟商服务群汇报当日业务情况。</div>
            <div class=" ">（2）配合村口科技和银行人员的现场巡查、电话回访工作。</div>
            <div class=" ">（3）关注服务群、公众号中发出的信息、培训课程，认真学习，提高业务技能。</div>
            <div class=" ">（4）按时参加月例会、培训会。</div>
            <div class=" ">（5）服从、配合村口科技、村口科技合作方要求的其他规范性动作。</div>
            <div class=" ">（6）禁止过度收集、记录、泄露客户信息（含身份证号、身份证照片、银行卡号）等违规行为，客户信息须妥善保存，合理使用。</div>
            <div class=" ">3.2其他业务管理</div>
            <div class="indent5 ">根据服务站业务发展，新增的其他业务规范另行约定。</div>
            <div class=" mt3 bold title">第二章 加盟商考核</div>
            <div class="indent5 ">对加盟商考核以站点管理考核和业务运营考核双重考核，总分100分，站点管理考核占30分，业务运营考核占70分。其中业务运营考核根据服务站开展的业务数量、类型制定不同的考核办法。</div>
            <div class=" bold mt3">一、站点管理考核</div>
            <div class="indent5 ">若该考核项为《十严禁》其中条款，一旦违反，则该项考核分直接为0。</div>
            <div class="">
                <table border="1" bordercolor="black" width="100%" align="center"  class="tableCenter">
                    <tr class="bold tableCenter" >
                        <td style="width: 40px">分类</td>
                        <td>考核项</td>
                        <td style="width: 65px">评分依据</td>
                        <td style="width: 50px">考核分</td>
                    </tr>
                    <tr >
                        <td rowspan="7" style="padding: 0 5px"> 服 务 站 管 理</td>
                        <td align="left">服务站积灰未打扫或堆放杂物</td>
                        <td>现场巡查</td>
                        <td>-1 分</td>
                    </tr>
                    <tr >
                        <td align="left">门头、标识牌、制度牌、合规经营承诺书、“十严禁”等未按标准悬挂</td>
                        <td>现场巡查</td>
                        <td>-2 分</td>
                    </tr>
                    <tr >
                        <td align="left">门头、标识牌、制度牌污损</td>
                        <td>现场巡查</td>
                        <td>-2 分</td>
                    </tr>
                    <tr >
                        <td align="left">设备损坏未上报或及时更换</td>
                        <td>现场巡查</td>
                        <td>-5 分</td>
                    </tr>
                    <tr >
                        <td align="left">监控离线超过 3 天未整改</td>
                        <td>监控管理</td>
                        <td>-5 分</td>
                    </tr>
                    <tr >
                        <td align="left">私自移动监控位置/角度，经通知仍未修正</td>
                        <td>监控管理</td>
                        <td>-5 分</td>
                    </tr>
                    <tr >
                        <td align="left">多次巡查未开门经营（当月内 2 次及以上）</td>
                        <td>现场巡查</td>
                        <td>-10 分</td>
                    </tr>
                </table>
            </div>
            <div class=" mt3 bold">二、业务运营考核</div>
            <div class=" mt3 bold">1．农村普惠金融业务运营考核</div>
            <div class="indent5 ">根据运营部通过当月对加盟商的巡查、暗访、回访、系统等线上线下管理过程的情况综合评定，从经营规范、督导配合2个方面进行。考核采用扣分制，每月基础分为70分，每项问题扣减对应分数，最低0分。</div>
            <div class="indent5 ">若该考核项为《十严禁》其中条款，一旦违反，则该项考核分直接为0。</div>
            <div class=" mt3 bold">1.1考核标准</div>
            <div class="">
                <table border="1" bordercolor="black" width="100%" align="center"  class="tableCenter">
                    <tr class="bold tableCenter" >
                        <td style="width: 40px">分类</td>
                        <td>考核项</td>
                        <td style="width: 65px">评分依据</td>
                        <td style="width: 55px">考核分</td>
                    </tr>
                    <tr >
                        <td rowspan="9" style="padding: 0 5px"> 经营规范</td>
                        <td align="left">使用“高息”“揽储”等敏感词汇开展宣传</td>
                        <td>巡查投诉</td>
                        <td>-5 分</td>
                    </tr>
                    <tr >
                        <td align="left">未在服务站区域内办理业务</td>
                        <td>巡查投诉</td>
                        <td>-10 分</td>
                    </tr>
                    <tr >
                        <td align="left">违规向客户收取费用</td>
                        <td>巡查投诉</td>
                        <td>-10 分</td>
                    </tr>
                    <tr >
                        <td align="left">私自印制带有银行或村口科技 LOGO 的名片、工牌、宣传单等广告标识</td>
                        <td>现场巡查</td>
                        <td>-10 分</td>
                    </tr>
                    <tr >
                        <td align="left">代替客户保管身份证或复印件、银行卡、存折、存单或密码</td>
                        <td>现场巡查</td>
                        <td>-10 分</td>
                    </tr>
                    <tr >
                        <td align="left">办理业务未按要求在登记薄上登记</td>
                        <td>现场巡查</td>
                        <td>-5 分</td>
                    </tr>
                    <tr >
                        <td align="left">登记簿或凭证无客户签字或代替客户签字</td>
                        <td>现场巡查</td>
                        <td>-5 分</td>
                    </tr>
                    <tr >
                        <td align="left">未经允许经营非合作相关及相似业务</td>
                        <td>现场巡查</td>
                        <td>-20 分</td>
                    </tr>
                    <tr >
                        <td align="left">客户投诉且经调查属于加盟商责任的</td>
                        <td>投诉监督</td>
                        <td>-20 分</td>
                    </tr>
                    <tr >
                        <td rowspan="4" style="padding: 0 5px">督导配合</td>
                        <td align="left">服务群无业绩汇报</td>
                        <td>服务群</td>
                        <td>-1分/天</td>
                    </tr>
                    <tr >
                        <td align="left">无故未参加月例会、培训会</td>
                        <td>例会培训</td>
                        <td>-30 分</td>
                    </tr>
                    <tr >
                        <td align="left">拒绝运营人员巡查时进入办公区，或拒绝检查登记簿、小票、抽屉或保险柜等</td>
                        <td>现场巡查</td>
                        <td>-50 分</td>
                    </tr>
                    <tr >
                        <td align="left">巡查回访不配合、态度恶劣</td>
                        <td>语音客服</td>
                        <td>-10 分</td>
                    </tr>
                </table>
            </div>
            <div class="bold mt3 ">三、考核评定</div>
            <div class="indent5 ">每月1号至5号，为服务站考核评定期，系统对上月服务站管理责任人、地区负责人、监控管理、语音客服、风控管理、投诉监督及其它各方提交信息数据进行汇总，村口科技内部对评定结果进行审核确认。</div>
            <div class="bold mt3 ">四、考核结果公示</div>
            <div class="indent5 ">考核结果作为加盟商管理档案留存，当月考核评定期结束后，各加盟商得分、扣分、排名情况由村口壹站系统公示。</div>
            <div class="indent5 ">加盟商对公示结果存在异议的，由服务站管理责任人进行明细讲解，属统计错误的，向村口科技相关部门申请得分重置（10号前）。</div>
            <div class="bold mt3 ">五、服务费核算</div>
            <div class=" ">1．核算公式</div>
            <div class=" ">（1）基础服务费=标准服务费×90%。</div>
            <div class=" ">（2）考核服务费=标准服务费×10%×月度考核得分÷100。</div>
            <div class=" ">（3）加盟商总服务费=【基础服务费】+【行为考核服务费】。</div>
            <div class=" ">举例：</div>
            <div class="indent5 ">若加盟商本月的标准服务费为3000元，月度考核得分为80分，则其总服务费为：总服务费=[(3000×90%)+(3000×10%×80÷100)]元=2940元。</div>
            <div class=" ">2．核算规则</div>
            <div class=" ">（1）核算数据以村口科技合作方提供的相关数据为准</div>
            <div class=" ">（2）加盟商因违规操作，造成客户纠纷的，根据责任划分情况承担相应损失。</div>
            <div class=" ">（3）农村普惠金融业务服务费核算规则：</div>
            <div class="indent5 ">村口科技按照归属于加盟商服务站的各类账户金融资产年日均余额的<span class="underline">4</span>‰向其支付服务费，每月核对结算，计算公式为月日均余额*0.33‰，所属加盟商服务站的账户余额数据以村口科技合作银行提供的数据为准。</div>
            <div class=" ">（4）标准服务费由于合作银行的政策调整造成站长的收益变动的，将由合作银行提前进行相应的说明工作，并以合作银行确定后的费率标准通知和执行，由此造成的相关责任与村口科技无关</div>
            <div class=" ">4．退出服务费核算</div>
            <div class="indent5 ">加盟商退出须签署《合同终止协议书》，服务费按照核算规则正常核算。</div>
            <div class=" mt3 title bold">第三章 加盟商退出</div>
            <div class="bold mt3">一、退出的类型</div>
            <div class="bold mt3">1.低效退出</div>
            <div class=" indent5">站点符合以下低效退出标准的，公司有权对站点启动退出流程。</div>
            <div class=" ">（1）自签署项目合作协议之日起，两个月内达不到开业标准的；</div>
            <div class=" ">（2）签约后半年内无月日均余额达到 200 万元月份的；</div>
            <div class=" ">（3）签约后一年内无月日均余额达到 300 万元月份的。</div>
            <div class="bold mt3 ">2．考核退出</div>
            <div class="indent5 ">站点月度考核符合以下标准的，公司有权对站点启动退出流程。</div>
            <div class=" ">（1）加盟商当月行为考核得分为 0 的；</div>
            <div class=" ">（2）加盟商连续两个月行为考核得分 50 分以下的；</div>
            <div class=" ">（3）加盟商连续三个月行为考核得分 60 分以下的。</div>
            <div class="bold mt3 ">3.其他退出</div>
            <div class="indent5 ">站点符合以下情况范围内的，公司有权对站点启动退出流程。另因其行为对合作银行、公司、客户造成损失的，加盟商应承担相应法律责任并进行赔偿。</div>
            <div class=" ">（1）对违反“十严禁”规定的；</div>
            <div class=" ">（2）经查实有违法犯罪记录的或运营期间发生违法犯罪行为的；</div>
            <div class=" ">（3）其他符合与加盟商签订的《村口壹站加盟合作协议》中退出约定条件的。</div>
            <div class="bold mt3 ">二、退出流程</div>
            <div class="bold mt3 ">1．村口科技合作方负责</div>
            <div class=" ">（1）根据站点业绩与相关协议核对公司收益，并按照双方合作协议约定结算时间进行结清。</div>
            <div class=" ">（2）对加盟商名下的所有客户进行回访，对客户资产进行核对。</div>
            <div class=" ">（3）就农村普惠金融服务业务，合作银行要收回或者终止 POS 商户协议，撤下银行标识牌，收回在服务站布放的银行设备和投放的宣传资料。</div>
            <div class=" ">（4）在站点原址及村委张贴项目退出公告，告知村民该站点已撤销，不再办理业务，并引导村民临时到附近的站点办理业务。</div>
            <div class="bold mt3 ">三、退出服务费结算</div>
            <div class="indent5 ">加盟商退出时，其服务费核算根据本规定第二章第五条第4点相关规定执行。</div>
            <div class="bold mt3">附件</div>
            <div class=" ">《加盟商十严禁》及《农村普惠金融业务合规经营承诺书》</div>
            <div class="title center bold mt3">加盟商十严禁</div>
            <div class="mt3">一、严禁以任何形式组织或参与非法集资、民间融资、高利贷、违规担保等活动。</div>
            <div class="mt3">二、严禁未经客户本人同意为其办理银行业务，严禁编造、虚构客户信息资料。</div>
            <div class="mt3">三、严禁代理客户保管存折、存单、银行卡、密码、身份证件、网银认证工具等。</div>
            <div class="mt3">四、严禁代替客户在各种凭条上签名及代替客户设置或修改密码。</div>
            <div class="mt3">五、严禁过度收集、窃取和泄露客户信息等违规行为。</div>
            <div class="mt3">六、严禁为客户打白条，不及时将客户资金入账。</div>
            <div class="mt3">七、严禁利用服务点支付终端等设备进行欺诈、套现等不正当交易。</div>
            <div class="mt3">八、严禁使用假币对外支付。</div>
            <div class="mt3">九、严禁借用村口科技合作方名义销售任何非村口科技合作方相关金融产品。</div>
            <div class="mt3">十、严禁参与黄、赌、毒活动及邪教组织。</div>
            <div class=" "></div>
            <div class="title center bold mt3">农村普惠金融业务合规经营承诺书</div>
            <div class="mt3">为促进普惠金融服务健康合规发展，现做出以下承诺：</div>
            <div class="indent5">1.保证合法合规开展业务，不向客户收取任何额外费用，不利用合作方银行与村口科技名义牟取不正当收益。</div>
            <div class="indent5">2.保证不参与任何形式的民间借贷、非法集资、高利贷、违规担保或者其他高危金融活动。</div>
            <div class="indent5">3.保证不打白条办理业务，不挪用客户资金，保证客户办理业务实时到账。</div>
            <div class="indent5">4.保证不代客户保管银行卡、存折、网银认证工具等产品，不代客户签名及设置或修改密码。</div>
            <div class="indent5">5.保证不以任何方式泄露商业秘密和客户资料，不损害客户、合作方银行和村口科技的正当利益。</div>
            <div class="indent5">6.保证不同时经营其他银行或其他与村口科技普惠金融相关业务。</div>
            <div class="indent5">7.保证不参与黄、赌、毒活动及邪教组织。</div>
            <div class="indent5">8.本人自愿遵守本承诺，接受大家监督。若有违反，本人自愿承担撤除服务点、追究法律责任等后果。</div>
            <div class="mt3">承诺人：{{agreementParams.para_Id_Name}}</div>
            <div class="mt3">承诺日期： {{agreementParams.para_date_year}} 年  {{agreementParams.para_date_month}}  月  {{agreementParams.para_date_day}}  日</div>

        </div>
        <div class="footer" v-show="signatureShow">
            <van-button type="info" color="#3F7C53" @click="signatureClick">签名</van-button>
        </div>
    </div>
</template>

<script>
    import {COMPANY_NAME} from '@/utils/constantNum'
    import { Button } from 'vant'
    import common from '@/utils/common'
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name:'managementStandardOfFranchiseeZZ',
        components:{
            [Button.name]: Button
        },
        data() {
            return {
                signatureShow: false,
                agreementParams: {},
                exampleStr:'',
                companyName:COMPANY_NAME,
            }
        },
        mounted(){
            this.$nextTick(()=>{
                this.signatureShow = this.$route.query.sign == 'sign' ? true : false
                if(this.$route.query.agreementParams){
                    this.agreementParams = JSON.parse(this.$route.query.agreementParams)
                }
            })
            uwStatisticAction('/agreement/managementStandardOfFranchiseeZZ','附件二：村口壹站加盟商管理规范')
        },
        methods: {
            signatureClick() {
                this.$router.push({name:'signatureFour', query:{stationNo: this.$route.query.stationNo}})
            }
        }
    }
</script>

<style lang="less" scoped>
    .managementStandardOfFranchisee{
        background: #f5f5f5;
        font-size: 0.3rem;
        box-sizing: border-box;
        padding: 20px 0.3rem 100px 0.3rem ;
        -webkit-overflow-scrolling: touch;
        .content{
            .title{
                font-size: 0.4rem;
            }
            .bold{
                font-weight: bold
            }
            .center{
                text-align: center;
            }
            .indent5{
                text-indent: 0.6rem;
            }
            .mt3{
                margin-top: 0.3rem;
            }
            .underline{
                text-decoration: underline;
            }
            .tableCenter{
                vertical-align:middle;
                text-align:center;
            }
            table{
                td{
                    padding:2px
                }
            }
        }
        .footer{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            .van-button{
                width: 100%;
            }
        }
    }
</style>
